import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "./services/loader.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ElectronService } from "ngx-electronyzer";
import { Plugins } from "@capacitor/core";
import { AuthService } from "./services/auth.service";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import * as firebase from "firebase/compat";
import { GqlSearchService } from "./services/gql-search.service";

const { LocalNotifications, Modals, Toast } = Plugins;

const appPages = []; /* [
  {
    title: 'Accounts',
    url: '/accounts',
    icon: 'people-outline'
  },
  {
    title: 'Content',
    url: '/content',
    icon: 'create-outline'
  }
];*/

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public configs = [
    {
      title: "Collections",
      url: "/collections_",
      icon: "albums-outline",
    },
    {
      title: "Schemas",
      url: "/schemas_",
      icon: "server-outline",
    },
  ];
  public appPages = [];
  public links = [
    {
      title: "Analytics",
      url: "https://analytics.google.com/analytics/web/?hl=en_US#/p193989472/reports/home",
      icon: "logo-google",
    },
    {
      title: "Database",
      url: "https://console.firebase.google.com/project/alcal-master/firestore",
      icon: "logo-firebase",
    },
    {
      title: "Functions",
      url: "https://console.firebase.google.com/project/alcal-master/functions/list",
      icon: "logo-web-component",
    },
    {
      title: "Repository",
      url: "https://github.com/adamlanferman?tab=repositories",
      icon: "logo-github",
    },
  ];
  public tools = [
    {
      title: "Admins",
      url: "/admins_",
      icon: "people-circle-outline",
    },
    {
      title: "API Playground",
      url: "/tools/playground",
      icon: "play-outline",
    },
    {
      title: "Processes",
      url: "/tools/processes",
      icon: "code-working-outline",
    },
    {
      title: "Hot Jar",
      url: "/tools/hotJar",
      icon: "finger-print",
    },
    {
      title: "Expense Report",
      url: "/tools/expenseReport",
      icon: "cash-outline",
    },

    /*{
      title: 'Update Index',
      handler: 'fullIndex',
      icon: 'refresh-circle-outline'
    },*/
  ];

  searchCollections: any[];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private fs: AngularFirestore,
    private fns: AngularFireFunctions,
    private electronService: ElectronService,
    public auth: AuthService,
    private gql: GqlSearchService,
  ) {
    auth.userObs.subscribe();
    this.initializeApp();
    if (this.electronService.isElectronApp) {
      console.log("I AM ELECTRON");
      //this.scheduleNotification()
      this.electronService.ipcRenderer.on("trigger-alert", this.showConfirm);
    }
    fs.collection("collections_")
      .valueChanges()
      .subscribe((cols) => {
        this.searchCollections = cols.filter((col: any) => col.index);
        this.appPages = appPages.concat(
          ...cols
            .map((col: any) => ({
              id: col.id,
              title: col.name,
              url: `/${col.id}`,
              icon: col.icon,
            }))
            .filter((col: any) => !col.id.endsWith("_") || col.id === "apis_"),
        );
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {}

  async handle(handler) {
    await this[handler]();
  }

  async fullIndex() {
    console.log("starting index...");
    //const fn = firebase.functions().httpsCallable('fullIndex', {timeout: 300000 });
    const fn = this.fns.httpsCallable("fullIndex");
    const done = await fn({}).toPromise();
    console.log(done);
  }

  async scheduleNotification() {
    LocalNotifications.schedule({
      notifications: [
        {
          title: "My Test notification",
          body: "My notificaiton content",
          id: 1,
          schedule: { at: new Date(Date.now() + 1000 * 5) },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null,
        },
      ],
    });
  }
  async show() {
    await Toast.show({
      text: "Hello!",
    });
  }
  async showConfirm() {
    let confirmRet = await Modals.confirm({
      title: "Confirm",
      message: "Are you sure you'd like to press the red button?",
    });
    console.log("Confirm ret", confirmRet);
  }
}
